<template>
	<v-card class="mx-auto my-5" rounded="xl" max-width="600">
		<v-card-title>{{ $t('courses.makeAPublication') }}</v-card-title>
		<v-card-text class="pb-0">
			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="title"
					:label="$t('newsfeed.title')"
					:placeholder="$t('newsfeed.titleToRemember')"
					:rules="rules.title"
					outlined
					rounded
					hide-details="auto"
					class="pb-2"
				/>
				<v-textarea
					v-model="content"
					:label="$t('newsfeed.content')"
					:rows="1"
					:rules="rules.content"
					:counter="contentMaxLength"
					auto-grow
					outlined
					rounded
					hide-details="auto"
					class="pb-2"
				/>
			</v-form>
		</v-card-text>

		<v-card-actions class="d-flex align-center justify-center flex-wrap pt-0 pb-5">
			<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callPublishPost()">
				{{ $t('newsfeed.share') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'FeedNewPost',
	props: {
		course: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			valid: false,
			title: '',
			content: '',
			rules: {
				title: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.titleMaxLength) || this.$t('rules.length', { length: this.titleMaxLength }),
				],
				content: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.contentMaxLength) || this.$t('rules.length', { length: this.contentMaxLength }),
				],
			},
		}
	},
	computed: {
		titleMaxLength() {
			return 64
		},
		contentMaxLength() {
			return 1024 * 10
		},
	},
	methods: {
		callPublishPost() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.publishPost({
				courseID: this.course.id,
				title: this.title,
				content: this.content,
			})
				.then((data) => {
					if (data.success) {
						this.title = ''
						this.content = ''
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('feed', ['publishPost']),
	},
}
</script>

<style scoped></style>
